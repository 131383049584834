.wrp {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 100;
}

.wrp > div {
  width: 100%;
}
