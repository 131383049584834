@import './WaiverWidget';
@import './Buttons';
@import './../components/common/Preloader';
@import './../components/common/PlaceholderInput';
@import './../components/common/NotificationService';
@import './../components/common/Modal';
@import './../components/common/ReactDatepicker/custom-datepicker.css';

* {
  box-sizing: border-box;
  font-family: 'Poppins';
}

body {
  fonts-size: 12px;
  height: 100vh;
  background: rgba(248, 248, 248, 1);
  margin: 0;
}

// #root {
//   height: 100%;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }

.container {
  margin: 0 auto;
  max-width: 288px;
  @media (min-width: 768px) {
    max-width: 481px;
  }

  @media (min-width: 1440px) {
    max-width: 541px;
  }
}

.formik-input {
  padding: 16px;
  border: 1px solid rgba(201, 217, 231, 1);
  background: #f8f8f8;
  border-radius: 8px;
  &::placeholder {
    color: rgba(119, 119, 119, 1);
  }
}
