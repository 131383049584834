.container {
  width: 100%;
  text-align: center;
}

.progress {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: black;
  transition: 0.4s linear;
  transition-property: width, background-color;
  overflow: hidden;
}

.progress-striped .progress-bar {
  background-color: #fcbc51;
  width: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(255, 226, 217, 1) 25%,
    rgba(232, 232, 232, 1) 25%,
    rgba(232, 232, 232, 1) 50%,
    rgba(190, 233, 199, 1) 50%,
    rgba(190, 233, 199, 1) 75%,
    rgba(255, 227, 184, 1) 75%,
    rgba(255, 227, 184, 1)
  );
  animation: progressAnimationStrike 6s;
}

.progress-striped .progress-bar-cyclic {
  background-color: #fcbc51;
  width: 180%;
  margin-left: -35%;
  height: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(255, 226, 217, 1) 25%,
    rgba(232, 232, 232, 1) 25%,
    rgba(232, 232, 232, 1) 50%,
    rgba(190, 233, 199, 1) 50%,
    rgba(190, 233, 199, 1) 75%,
    rgba(255, 227, 184, 1) 75%,
    rgba(255, 227, 184, 1)
  );
  animation: progressAnimationStrikeCyclic 2s infinite;
}

@keyframes progressAnimationStrike {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes progressAnimationStrikeCyclic {
  0% {
    transform: translateX(-20%);
  }
  50% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(-20%);
  }
}
