@media (min-width: 768px) {
}

@media (min-width: 1440px) {
}

.container {
  @media (max-width: 482px) {
    max-width: 100%;
  }
}

.hide {
  opacity: 0;
  height: 0px;
  transform: scale(0);
}

.show {
  transition: all 0.5s ease;
  animation: all 0.5s ease;
  opacity: 1;
  height: initial;
  transform: scale(1);
}

.waiver-widget {
  padding-bottom: 40px;
  transition: all 1s ease;
  animation: all 1s ease;

  &--logo {
    margin: 24px 0 8px;

    > svg {
      width: 163px;
    }

    @media (min-width: 768px) {
      > svg {
        width: 218px;
      }
    }

    @media (min-width: 1440px) {
      margin: 32px 0 16px;
      > svg {
        width: 272px;
      }
    }

    display: flex;
    width: 100%;
    justify-content: center;
  }

  > span {
    display: block;
    text-align: center;
    color: rgba(119, 119, 119, 1);
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 24px;
    @media (min-width: 768px) {
      margin-bottom: 32px;
    }

    @media (min-width: 1440px) {
      margin-bottom: 48px;
    }
  }

  &--container {
    transition: all 1s ease;
    animation: all 1s ease;
    width: 100%;
    padding: 0.75rem;
    background: white;
    box-shadow: 0px 0px 10px 0px rgba(34, 34, 34, 0.05);
    border-radius: 16px;
    @media (min-width: 768px) {
      padding: 24px;
    }
    @media (min-width: 1440px) {
      padding: 32px;
    }
  }

  &--enter-booking-number {
    > span:nth-child(1) {
      color: #111111;
      font-weight: 600;
      font-size: 24px;
      display: block;
      line-height: 40px;
      margin-bottom: 4px;
      text-align: center;
      @media (min-width: 768px) {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 8px;
      }

      @media (min-width: 1440px) {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 8px;
      }
    }

    > span:nth-child(2) {
      display: block;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      margin: 0px 7px 16px;
      color: rgba(68, 68, 68, 1);

      @media (min-width: 768px) {
        margin: 0px 60px 24px;
      }

      > i {
        color: rgba(29, 126, 217, 1);
        font-weight: 600;
        font-style: normal;
      }
    }

    .formik-input {
      width: 100%;
      margin-bottom: 16px;
      @media (min-width: 768px) {
        margin-bottom: 24px;
      }
    }
  }

  &--start-over-container {
    margin-top: 16px;
  }

  &--booking-result {
    transition: all 0.5s ease;
    animation: all 0.5s ease;

    > span:nth-child(1) {
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 8px;
      display: block;

      @media (min-width: 1440px) {
        font-size: 24px;
        font-weight: 600;
        line-height: 40px;
      }
    }

    > span:nth-child(2) {
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(68, 68, 68, 1);
      margin-bottom: 16px;
    }

    .button-gray {
      @media (min-width: 768px) {
        display: none;
      }

      padding: 0;
      > span {
        color: rgba(29, 126, 217, 1);
      }
      svg {
        path {
          fill: rgba(29, 126, 217, 1);
        }

        transform: rotate(0deg);
      }
    }
  }

  &--not-correct {
    > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      border: 1px solid rgba(126, 189, 223, 1);
      background: rgba(233, 243, 252, 1);
      color: rgba(29, 126, 217, 1);
      padding: 8px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;

      > p {
        font-size: inherit;
        margin: 0;
        font-weight: 600;
        line-height: 24px;
      }

      a {
        color: rgba(29, 126, 217, 1);
        font-size: inherit;
        margin: 0;
        font-weight: 600;
        line-height: 24px;
        text-decoration: none;
      }

      @media (min-width: 768px) {
        flex-direction: row;
        font-size: 14px;
      }
    }
  }

  &--split {
    width: 100%;
    height: 1px;
    background: rgba(235, 238, 240, 1);
    margin: 16px 0;
    @media (min-width: 768px) {
      margin: 24px 0;
    }
  }

  &--booking-code-wrapper {
    position: relative;

    > button {
      display: none;
      position: absolute;
      right: 0;
      transition: all 0.5s ease;
      animation: all 0.5s ease;
      background: transparent;
      border: none;
      cursor: pointer;

      > svg {
        transition: all 0.5s ease;
        animation: all 0.5s ease;
      }

      @media (min-width: 768px) {
        display: block;
      }

      @media (min-width: 1024px) {
        &:hover {
          transition: all 0.5s ease;
          animation: all 0.5s ease;
          transform: scale(1.1);

          > svg {
            > path {
              fill: white;
            }

            > rect {
              fill: rgba(29, 126, 217, 1);
            }
          }
        }
      }
    }
  }

  &--title-text {
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;

    > p {
      margin: 0;
      font-size: inherit;
      color: rgba(29, 126, 217, 1);
    }

    @media (min-width: 768px) {
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
    }
  }

  &--booking-info {
    display: flex;
    margin-bottom: 16px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    > span {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      margin-right: 8px;
      color: rgba(68, 68, 68, 1);
      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }

  &--enter-phone-number {
    > span {
      margin-bottom: 8px;
      @media (min-width: 768px) {
        margin-bottom: 16px;
      }
    }

    .formik-input {
      width: 100%;
      margin-bottom: 16px;
      @media (min-width: 768px) {
        margin-bottom: 24px;
      }
    }
  }

  &--buttons-container {
    display: flex;

    > button:nth-child(1) {
      margin-right: 8px;
    }

    > button:nth-child(2) {
      margin-left: 8px;
    }
  }

  &--input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    @media (min-width: 768px) {
      margin-bottom: 16px;
    }

    .formik-input {
      width: 100%;
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      position: absolute;
      right: 8px;
      height: 100%;
      cursor: pointer;
      animation: all 0.2s ease;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.2);
      }
    }

    .checked {
      > svg > path {
        fill: rgba(29, 126, 217, 1);
      }
    }
  }

  &--timer-button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(119, 119, 119, 1);
    margin: 0 auto 16px;
    @media (min-width: 768px) {
      margin: 0 auto 24px;
    }

    &.enabled {
      color: rgba(29, 126, 217, 1);
      text-decoration: underline;
    }
  }

  &--enter-sms-code {
    .button-gray {
      > span {
        color: rgba(29, 126, 217, 1);

        > svg > path {
          fill: rgba(29, 126, 217, 1);
        }
      }
    }
  }

  &--timer-button {
    > p {
      margin: 0;
    }
  }

  &--profile {
    border-radius: 8px;
    padding: 16px;
    border: 1px solid rgba(201, 217, 231, 1);
    background: rgba(248, 248, 248, 1);
    animation: all 0.5s ease;
    transition: all 0.5s ease;
    margin-bottom: 16px;
    @media (min-width: 1024px) {
      &:not(.without-hover):hover {
        border: 1px solid rgba(29, 126, 217, 1);
        transform: scale(1.005);
      }
    }

    > span {
      margin-bottom: 4px;
      display: block;
      color: rgba(119, 119, 119, 1);
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;

      > i {
        font-style: normal;
        font-size: inherit;
        color: rgba(68, 68, 68, 1);
      }

      @media (min-width: 768px) {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }

      &:nth-child(1) {
        color: #333333;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;

        @media (min-width: 768px) {
          font-size: 18px;
          line-height: 32px;
        }
      }

      &:nth-child(4) {
        color: #333333;
        font-weight: 500;
        margin-bottom: 8px;
        @media (min-width: 768px) {
          margin-bottom: 16px;
        }
      }
    }

    > button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;
      color: rgba(29, 126, 217, 1);
      padding: 0;
      line-height: 32px;
      margin-left: -8px;

      > svg {
        > path {
          fill: rgba(29, 126, 217, 1);
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  &--text {
    font-size: 12px;
    font-weight: 400;
    color: rgba(68, 68, 68, 1);
    line-height: 24px;

    > i {
      font-size: inherit;
      font-weight: inherit;
      color: rgba(119, 119, 119, 1);
      font-style: normal;
    }

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  &--create-profile {
    > span:nth-child(2) {
      display: block;
      margin-bottom: 8px;
      @media (min-width: 768px) {
        margin-bottom: 16px;
      }
    }
  }

  &--two-flex {
    @media (min-width: 768px) {
      display: flex;
      input {
        width: 100%;
        margin-bottom: 8px;
        @media (min-width: 768px) {
          margin-bottom: 16px;
        }
      }
    }

    input:nth-child(1) {
      @media (min-width: 768px) {
        margin-right: 8px;
      }
    }

    input:nth-child(2) {
      @media (min-width: 768px) {
        margin-left: 8px;
      }
    }
  }

  &--create-profile {
    .waiver-widget--buttons-container {
      margin-top: 8px;
      @media (min-width: 768px) {
        margin-top: 16px;
      }
    }

    .formik-input {
      width: 100%;
      margin-bottom: 8px;
      @media (min-width: 768px) {
        margin-bottom: 16px;
      }
    }
  }

  &--buttons-selector {
    width: 100%;
    display: flex;
    margin: 12px 0;
  }

  &--select-button {
    border: 1px solid rgba(201, 217, 231, 1);
    border-right: none;
    color: rgba(68, 68, 68, 1);
    background: rgba(248, 248, 248, 1);
    width: 100%;
    cursor: pointer;
    height: 38px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    animation: all 0.2s ease;
    transition: all 0.2s ease;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    > span:nth-child(1) {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }

    > span:nth-child(2) {
      display: block;
      @media (min-width: 768px) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      font-size: 14px;
    }

    @media (min-width: 1024px) {
      &:hover:not([disabled]):not(.checked) {
        background: rgba(233, 243, 252, 1);
        color: rgba(29, 126, 217, 1);
      }
    }

    &:nth-child(1) {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-right: 1px solid rgba(201, 217, 231, 1);
      border-radius: 0 8px 8px 0;

      &.checked {
        background: rgba(29, 126, 217, 1);
        color: white;
        border-color: rgba(29, 126, 217, 1);
      }
    }

    &.checked {
      background: rgba(29, 126, 217, 1);
      color: white;
      border-color: rgba(29, 126, 217, 1);
    }
  }

  &--select-profile {
    .button-blue {
      margin-bottom: 16px;
    }
  }

  &--notif {
    width: 100%;
    display: block;
    text-align: center;
    background: rgba(253, 247, 229, 1);
    border: 1px solid rgba(243, 240, 219, 1);
    color: rgba(136, 109, 66, 1);
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;

    &.error {
      background: rgba(253, 229, 229, 1);
      border: 1px solid rgba(228, 180, 180, 1);
      color: rgba(191, 57, 56, 1);
    }

    i {
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      font-style: normal;
    }

    font-size: 12px;
    font-weight: 400;
    line-height: 24px;

    @media (min-width: 768px) {
      margin-bottom: 16px;
      font-size: 14px;
      i {
        font-size: 14px;
      }
    }
  }

  &--add-minor {
    .waiver-widget--title-text {
      margin-bottom: 16px;
    }
  }

  &--new-minor {
    border: 1px solid rgba(201, 217, 231, 1);
    background: rgba(248, 248, 248, 1);
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;

    &.error {
      .button-blue:not([disabled]) {
        animation: savex2 1s infinite;
      }

      .button-gray {
        animation: savex2 1s infinite;
      }
    }

    .placeholder-input {
      margin-bottom: 8px;
      @media (min-width: 768px) {
        margin-bottom: 16px;
      }
    }

    .button-blue:disabled {
      cursor: default;
      background: rgba(255, 255, 255, 1);
    }
  }

  &--minor {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(201, 217, 231, 1);
    background: rgba(248, 248, 248, 1);
    position: relative;
    margin-bottom: 8px;
    animation: all 0.2s ease;
    transition: all 0.2s ease;
    &.edit {
      border: 1px solid transparent;
      background: transparent;
      padding: 0;
    }

    .waiver-widget--new-minor {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      margin-bottom: 16px;
    }

    .waiver-widget--title-text {
      margin-bottom: 4px;
      @media (min-width: 768px) {
        margin-bottom: 8px;
      }
    }

    &.deleting {
      transform: scale(0);
    }

    .waiver-widget--text {
      i {
        display: none;
        @media (min-width: 768px) {
          display: inline-block;
        }
      }
    }
  }

  &--minor-icon {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &--buttons-minor-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    margin-top: 16px;
    @media (min-width: 768px) {
      font-size: 14px;
    }

    > button {
      width: auto;
      display: flex;
      align-items: center;
      background: none;
      border: none;
      height: 40px;
      line-height: 40px;
      padding: 0;
      cursor: pointer;
      animation: all 0.2s ease;
      transition: all 0.2s ease;

      svg {
        margin: auto;
        animation: all 0.2s ease;
        transition: all 0.2s ease;
      }

      &:disabled {
        filter: grayscale(1);
        opacity: 0.6;
        cursor: default;
      }

      @media (min-width: 1024px) {
        &:not([disabled]):hover {
          transform: scale(1.05);
          text-decoration: underline;

          svg {
            transform: scale(1.2);
          }
        }
      }
    }

    > button:nth-child(1) {
      margin-right: 8px;
      color: rgba(29, 126, 217, 1);

      svg {
        margin: auto 8px auto 0;
        width: 16px;
        height: 16px;
      }

      i {
        display: none;
        @media (min-width: 768px) {
          display: inline-block;
          font-style: normal;
        }
      }
    }

    > button:nth-child(2) {
      margin-left: 8px;
      color: rgba(191, 57, 56, 1);

      svg {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }
  }

  &--text-block {
    margin-bottom: 16px;

    &:first-child {
      margin-top: 6px;
    }

    @media (min-width: 768px) {
      margin-bottom: 24px;
      &:first-child {
        margin-top: 12px;
      }
    }

    .waiver-widget--text {
      margin-bottom: 8px;
      @media (min-width: 768px) {
        margin-bottom: 16px;
      }
    }
  }

  &--checkbox-container {
    display: block;
    position: relative;
    min-height: 24px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    padding-left: 36px;

    &.error {
      border-radius: 8px;
      background: rgba(253, 229, 229, 0.8);
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border-radius: 4px;
      background-color: rgba(248, 248, 248, 1);
      border: 1px solid rgba(201, 217, 231, 1);
      animation: all 0.2s ease;
      transition: all 0.2s ease;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ span {
      background-color: rgba(29, 126, 217, 0.1);
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ span {
      background-color: rgba(29, 126, 217, 1);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    span:after {
      content: '';
      position: absolute;
      opacity: 0;
      transform: rotate(45deg) scale(0);
    }

    /* Show the checkmark when checked */
    input:checked ~ span:after {
      opacity: 1;
      transform: rotate(45deg);
    }

    /* Style the checkmark/indicator */
    span:after {
      left: 8px;
      top: 2px;
      width: 4px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  &--signature {
    .waiver-widget--buttons-container {
      margin-bottom: 8px;
      @media (min-width: 768px) {
        margin-bottom: 16px;
      }
    }
  }

  &--signature-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 256px;
    margin-bottom: 16px;
    @media (min-width: 768px) {
      height: 302px;
    }

    background: rgba(248, 248, 248, 1);
    border: 1px solid rgba(201, 217, 231, 1);
    border-radius: 8px;
    img {
      max-width: 80%;
      max-height: 90%;
      margin: 0 auto;
      position: absolute;
    }
  }

  &--accept-replace-modal {
    .modal--content {
      max-width: 280px;
      .waiver-widget--title-text {
        display: inline-block;
        margin-bottom: 32px;
      }
      i {
        display: inline-flex;
        font-style: normal;
        color: rgba(191, 57, 56, 1);
      }
    }
  }

  &--success-modal {
    .modal--content {
      max-width: 90%;
      @media (min-width: 768px) {
        max-width: 471px;
      }
      @media (min-width: 1440px) {
        max-width: 541px;
      }
    }
  }

  &--success-modal {
    .modal--content {
      padding: 24px;
    }

    .waiver-widget--text {
      margin-bottom: 16px;
    }
    .placeholder-input {
      width: 100%;
      margin-bottom: 16px;
      padding: 16px 40px 16px 16px;
      background: #f8f8f8;
      border: none;
    }

    .waiver-widget--title-text:nth-child(1) {
      font-size: 24px;
    }
  }

  &--send-link {
    .placeholder-input {
      //width: 75%;
    }
    display: flex;
    .button {
      width: auto;
      height: 56px;
      margin-left: 16px;
      padding: 0 16px;
    }
  }

  &--input-copy {
    display: flex;
    align-items: center;
    width: 100%;

    button {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 26px;
      width: 40px;
      height: 40px;
    }
  }

  &--modal-errors {
    .button-red {
      margin-bottom: 16px;
    }
  }
}

.footer {
  background: white;
  padding: 16px;
  color: rgba(68, 68, 68, 1);
  display: flex;
  flex-direction: column;
  &--main-info {
    display: flex;
    flex-wrap: wrap;
  }
  p {
    margin: 0 0 4px 0;
    @media (min-width: 768px) {
      margin: 0 0 8px 0;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 14px;
    align-items: center;
  }

  &--powered {
    color: rgba(119, 119, 119, 1);
    font-weight: 600;
  }
}

@keyframes savex2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
