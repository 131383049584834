.placeholder-input {
  height: 56px;
  border: 1px solid rgba(201, 217, 231, 1);
  background: white;
  padding: 16px;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  animation: all 0.5s ease;
  transition: all 0.5s ease;

  &--label {
    display: flex;
    width: 50px;
    color: rgba(153, 153, 153, 1);
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    height: 0;
    transform: scaleY(0);
    opacity: 0;
    animation: none;
    transition: none;
  }

  &--field {
    border: none;
    padding: 0;
    animation: all 0.5s ease;
    transition: all 0.5s ease;
    &:focus {
      outline: none;
      .placeholder-input {
        border: 1px solid rgba(29, 126, 217, 1);
      }
    }
    color: rgba(68, 68, 68, 1);
    &::placeholder {
      color: rgba(119, 119, 119, 1);
    }
  }

  &.not-empty {
    .placeholder-input--label {
      opacity: 1;
      animation: all 0.5s ease;
      transition: all 0.5s ease;
      transform: scaleY(1);
      height: auto;
    }
  }
}
