.modal {
  padding: 16px;
  @media (min-width: 768px) {
    padding: 24px;
  }
  @media (min-width: 1440px) {
    padding: 32px;
  }
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 200;
  animation: all 0.2s ease;
  transition: all 0.2s ease;

  &.active {
    pointer-events: all;

    .modal--filter {
      opacity: 1;
    }

    .modal--content {
      transform: scale(1);
    }
  }

  &--filter {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(29, 126, 217, 0.5);
    opacity: 0;
    animation: all 0.2s ease;
    transition: all 0.2s ease;
    backdrop-filter: blur(4px);
  }

  &--content {
    animation: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 8px;
    padding: 16px;
    background-color: white;
    transform: scale(0);
    filter: unset;
    z-index: 250;
    width: 100%;
    @media (min-width: 768px) {
      max-width: 352px;
    }
    @media (min-width: 1024px) {
      max-width: 476px;
    }
    @media (min-width: 1024px) {
      max-width: 656px;
    }
    @media (min-width: 1920px) {
      max-width: 570px;
    }
  }

  //&--content.active {
  //  animation: all 0.3s ease;
  //  transition: all 0.3s ease;
  //  transform: scale(1);
  //}
}
