.notification {
  font-family: Poppins;
  position: fixed;
  bottom: -150px;
  left: 40%;
  padding: 16px;
  margin: 0 auto;
  width: 280px;
  border-radius: 8px;
  border: 1px solid transparent;
  animation: all 0.5s ease;
  transition: all 0.5s ease;
  &.open {
    bottom: 20px;
  }
  svg {
    width: 18px;
    height: 18px;
  }

  > span:nth-child(1) {
    display: flex;
    align-items: center;
    color: white;
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    > svg {
      margin-right: 8px;
    }
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  > span:nth-child(2) {
    display: flex;
    color: white;
    font-size: 12px;
    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  &.warning {
    background: #fcbc51;
  }

  &.error {
    > span {
      display: block;
      color: white;
    }
    background: lightcoral;
    border: 1px solid rgba(228, 180, 180, 1);
  }
}
