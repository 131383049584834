.button {
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  font-weight: 500;
  height: 40px;
  padding: 8px 0;
  border-radius: 8px;
  font-family: inherit;
  animation: all 0.5s ease;
  cursor: pointer;
  transition: all 0.5s ease;
  position: relative;
  &.loading {
    padding: 0;
  }
  &-blue {
    background: rgba(29, 126, 217, 1);
    color: white;
    border: 1px solid transparent;

    &:disabled {
      background: rgba(248, 248, 248, 1);

      > span {
        color: rgba(153, 153, 153, 1);

        > svg > path {
          fill: rgba(153, 153, 153, 1);
        }
      }
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 24px;
      color: white;
      margin: 0 auto;
      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 24px;
      }

      > svg {
        animation: all 0.5s ease;
        transition: all 0.5s ease;
        margin-left: 8px;
      }
    }

    @media (min-width: 768px) {
      padding: 8px 32px 8px 32px;
      font-size: 14px;
      line-height: 24px;
    }

    @media (min-width: 1024px) {
      &:hover:not([disabled]) {
        transform: scale(1.02);

        > span {
          font-weight: 600;
        }

        > svg {
          transform: translateX(30px);
        }
      }
    }
  }

  &-gray {
    background: rgba(248, 248, 248, 0.9);
    color: rgba(119, 119, 119, 1);
    border: 1px solid rgba(119, 119, 119, 0.2);

    &.red {
      color: rgba(191, 57, 56, 1);

      > span {
        color: rgba(191, 57, 56, 1);
        > svg {
          path {
            fill: rgba(191, 57, 56, 1);
          }
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          transform: scale(1.02);
          border: 1px solid rgba(119, 119, 119, 1);
          > span {
            font-weight: 600;
            > svg {
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 24px;
      color: rgba(119, 119, 119, 1);
      margin: 0 auto;

      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 24px;
        > svg {
          margin-right: 8px;
        }
      }

      > svg {
        transform: rotate(180deg);
        animation: all 0.5s ease;
        transition: all 0.5s ease;
        margin-right: 4px;

        > path {
          fill: rgba(119, 119, 119, 1);
        }
      }
    }

    @media (min-width: 768px) {
      padding: 8px 32px 8px 32px;
      font-size: 14px;
      line-height: 24px;
    }

    @media (min-width: 1024px) {
      &:hover {
        transform: scale(1.02);

        > span {
          font-weight: 600;
        }

        > svg {
          transform: translateX(-30px);
        }
      }
    }
  }

  &-red {
    background: rgba(253, 229, 229, 1);
    color: rgba(191, 57, 56, 1);
    border: 1px solid transparent;
    &:disabled {
      background: rgba(248, 248, 248, 1);

      > span {
        color: rgba(153, 153, 153, 1);

        > svg > path {
          fill: rgba(153, 153, 153, 1);
        }
      }
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 24px;
      color: rgba(191, 57, 56, 1);
      margin: 0 auto;
      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 24px;
      }

      > svg {
        animation: all 0.5s ease;
        transition: all 0.5s ease;
        margin-left: 8px;
      }
    }

    @media (min-width: 768px) {
      padding: 8px 32px 8px 32px;
      font-size: 14px;
      line-height: 24px;
    }

    @media (min-width: 1024px) {
      &:hover:not([disabled]) {
        transform: scale(1.02);

        > span {
          font-weight: 600;
        }

        > svg {
          transform: translateX(30px);
        }
      }
    }
  }

  &-lightBlue {
    background: rgba(248, 248, 248, 1);
    color: rgba(29, 126, 217, 1);
    border: 1px solid transparent;
    &:disabled {
      background: rgba(248, 248, 248, 1);

      > span {
        color: rgba(153, 153, 153, 1);

        > svg > path {
          fill: rgba(153, 153, 153, 1);
        }
      }
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 24px;
      color: rgba(29, 126, 217, 1);
      margin: 0 auto;
      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 24px;
      }

      > svg {
        animation: all 0.5s ease;
        transition: all 0.5s ease;
        margin-left: 8px;
      }
    }

    @media (min-width: 768px) {
      padding: 8px 32px 8px 32px;
      font-size: 14px;
      line-height: 24px;
    }

    @media (min-width: 1024px) {
      &:hover:not([disabled]) {
        transform: scale(1.02);

        > span {
          font-weight: 600;
        }

        > svg {
          transform: translateX(30px);
        }
      }
    }
  }
}
